



















import { Component, Vue } from 'vue-property-decorator';
import { AuthClient } from '@/services/auth';
import ForgotPasswordFormComponent from '@/components/login/ForgotPasswordFormComponent.vue';
import ForgotPasswordFormErrorHeader from '@/components/login/ForgotPasswordFormErrorHeader.vue';

@Component<ForgotPasswordPage>({
  components: {
    ForgotPasswordFormComponent,
    ForgotPasswordFormErrorHeader
  }
})
export default class ForgotPasswordPage extends Vue {
  private email = '';
  private error = '';
  private showSpinner = false;

  get isLoggedIn() {
    const loggedIn =
      this.$store.getters['userModule/currentUser'] &&
      this.$store.getters['userModule/isLoggedIn'];

    if (loggedIn) {
      this.showSpinner = false;
      this.$router.replace({
        query: {
          redirect: 'home'
        }
      });
    }
    return loggedIn;
  }

  private onUpdateEmail(email: string) {
    this.email = email;
  }

  private onResetPassword() {
    this.sendResetPasswordEmail();
  }

  private async onSelectReturn() {
    this.$router.back();
  }

  private onClearError() {
    this.error = '';
  }

  private async sendResetPasswordEmail() {
    try {
      await AuthClient.getInstance().sendPasswordResetEmail(this.email);
      this.error = '';
      this.$router.push('verification');
    } catch (error) {
      this.error = error;
    }
  }
}
