var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "items-center justify-items-center" },
    [
      _vm._t("error-header"),
      _c(
        "form",
        { staticClass: "flex flex-col justify-start space-y-4 rounded" },
        [
          _c("h1", { staticClass: "mb-4 font-bold text-gray-700" }, [
            _vm._v("Forgot Password")
          ]),
          _c("div", { staticClass: "mb-4" }, [
            _c(
              "label",
              {
                staticClass: "block mb-2 text-sm text-gray-500 uppercase",
                attrs: { for: "email" }
              },
              [_vm._v(" login email ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "skyhub-input-field",
              attrs: { id: "email", type: "text", autocomplete: "email" },
              domProps: { value: _vm.email },
              on: {
                change: _vm.updateEmail,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "flex items-center justify-end" }, [
            _c(
              "button",
              {
                staticClass:
                  "px-4 py-2 text-white bg-black border-2 border-black border-none rounded disabled:bg-gray-400 font-roboto",
                attrs: { disabled: _vm.email == "", type: "button" },
                on: { click: _vm.resetPassword }
              },
              [_vm._v(" Reset password ")]
            )
          ]),
          _c(
            "div",
            { staticClass: "flex items-center justify-between mt-6" },
            [
              _c(
                "router-link",
                {
                  attrs: { to: {} },
                  nativeOn: {
                    click: function($event) {
                      return _vm.selectReturn($event)
                    }
                  }
                },
                [_vm._v("Return to login")]
              )
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }