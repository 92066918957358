





































import { Component, Vue } from 'vue-property-decorator';

@Component<ForgotPasswordLoginFormComponent>({})
export default class ForgotPasswordLoginFormComponent extends Vue {
  private email = '';

  private updateEmail() {
    this.$emit('update-email', this.email);
  }

  private resetPassword() {
    this.$emit('reset-password');
  }

  private selectReturn() {
    this.$emit('select-return');
  }
}
