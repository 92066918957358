var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col h-full" }, [
    _c(
      "div",
      { staticClass: "flex items-center justify-center h-full" },
      [
        _c(
          "forgot-password-form-component",
          {
            staticClass: "w-2/3 p-8 border border-gray-500 lg:w-1/2 xl:w-1/3",
            on: {
              "reset-password": _vm.onResetPassword,
              "select-return": _vm.onSelectReturn,
              "update-email": _vm.onUpdateEmail
            }
          },
          [
            _c("forgot-password-form-error-header", {
              attrs: { slot: "error-header", error: _vm.error },
              on: { "clear-error": _vm.onClearError },
              slot: "error-header"
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }