













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<ForgotPasswordFormErrorHeader>({})
export default class ForgotPasswordFormErrorHeader extends Vue {
  @Prop({ default: '' })
  private error!: string;
}
